import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { SidebarContainerComponent } from './sidebar-container/sidebar-container.component';

@NgModule({
  imports: [CommonModule, MatIconModule],
  exports: [SidebarContainerComponent],
  declarations: [SidebarContainerComponent],
})
export class SharedUiGridModule {}
