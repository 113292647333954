import {
  AfterViewInit,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { EventType, NavigationStart, Event as RouterEvent } from '@angular/router';
import { Router } from '@angular/router';
import { DataAccessScreensizeFacade } from '@planone/bau365/data-access-screensize';
import { ModalBottomsheetHelper, ModalBottomsheetType } from '@planone/shared/ui-elements';
import { DocumentRef } from '@planone/shared/util-browser-api';
import { filter, Observable, pairwise, startWith, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'planone-sidebar-container',
  templateUrl: './sidebar-container.component.html',
  styleUrls: ['./sidebar-container.component.scss'],
})
export class SidebarContainerComponent implements OnInit, AfterViewInit, OnDestroy {
  @ContentChild('teaser') teaser: TemplateRef<never> | null = null;
  @ContentChild('sidebar') sidebar: TemplateRef<never> | null = null;
  @ContentChild('header') header: TemplateRef<never> | null = null;
  @ContentChild('section') section: TemplateRef<never> | null = null;
  @ContentChild('modalTitle') modalTitle: TemplateRef<never> | null = null;
  @ContentChild('modalContent') modalContent: TemplateRef<never> | null = null;
  @ContentChild('modalActions') modalActions: TemplateRef<never> | null = null;
  @ViewChild('sidebarcontainerTeaser') sidebarcontainerTeaser?: ElementRef;

  @Input() showMobileBottomBar = false;
  @Input() closeDialog: EventEmitter<void> = new EventEmitter<void>();

  @Output() afterDialogClose: EventEmitter<void> = new EventEmitter<void>();

  size$: Observable<string | undefined>;

  teaserHeight = 0;
  footerSpace = 0;

  private unsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private modalBottomsheetHelper: ModalBottomsheetHelper,
    private documentRef: DocumentRef,
    private router: Router,
    ScreensizeFacade: DataAccessScreensizeFacade
  ) {
    this.size$ = ScreensizeFacade.size$;
  }

  ngOnInit(): void {
    this.router.events
      .pipe(
        takeUntil(this.unsubscribe),
        filter(this.isNavigationStart),
        startWith(undefined),
        pairwise(),
        filter(([historic, current]) => {
          let historicUrl = this.router.url.split('?')[0];

          if (historic) {
            const historicEvent = <NavigationStart>historic;

            historicUrl = historicEvent.url.split('?')[0];
          }

          const currentEvent = <NavigationStart>current;
          const currentUrl = currentEvent.url.split('?')[0];

          return historicUrl !== currentUrl;
        })
      )
      .subscribe(() => this.modalBottomsheetHelper.closeDialog());

    this.closeDialog.pipe(takeUntil(this.unsubscribe)).subscribe(() => this.modalBottomsheetHelper.closeDialog());
  }

  ngAfterViewInit(): void {
    this.checkTeaserSize();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  @HostListener('window:resize')
  onResize(): void {
    this.checkTeaserSize();
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event: Event) {
    this.checkTeaserSize();
    const footer = this.documentRef.nativeDocument.getElementById('footer');
    const target = <Window>event.currentTarget;

    if (footer) {
      this.footerSpace = this.calculateSpace(footer, target);
    } else {
      this.footerSpace = 0;
    }
  }

  expandMobileBottomsheet(showMobileBottomBar: boolean) {
    const bottomBarClass = showMobileBottomBar ? ['show-mobile-bottom-bar'] : [];
    this.modalBottomsheetHelper
      .openDialog(
        ModalBottomsheetType.general,
        this.modalTitle,
        this.modalContent,
        'phone',
        undefined,
        {
          panelClass: ['sidebar-bottom-sheet', ...bottomBarClass],
          backdropClass: ['sidebar-backdrop', ...bottomBarClass],
        },
        {},
        this.modalActions
      )
      .then(() => this.afterDialogClose.emit());
  }

  private calculateSpace(element: HTMLElement, target: Window) {
    const footerSpace =
      this.documentRef.nativeDocument.documentElement.scrollHeight -
      target.scrollY -
      target.innerHeight -
      element.scrollHeight;

    if (footerSpace < 0) {
      return footerSpace * -1;
    }

    return 0;
  }

  private checkTeaserSize(): void {
    const offSetHeight = this.sidebarcontainerTeaser?.nativeElement.offsetHeight;
    if (this.teaserHeight !== offSetHeight) {
      this.teaserHeight = offSetHeight;
    }
  }

  private isNavigationStart(e: RouterEvent | undefined) {
    if (!e || !Object.prototype.hasOwnProperty.call(e, 'type')) return false;
    const event = e as RouterEvent & { type: EventType };
    return event.type === EventType.NavigationStart;
  }
}
