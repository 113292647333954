<section class="teasercontainer" #sidebarcontainerTeaser>
  <ng-container *ngTemplateOutlet="teaser"></ng-container>
</section>
<section
  class="sidebarcontainer"
  [style]="{ '--grid-teaser-height': teaserHeight + 'px', '--grid-footer-height': footerSpace + 'px' }">
  <div class="mdc-layout-grid">
    <div class="mdc-layout-grid__inner">
      @if ((size$ | async) !== 'phone') {
      <div
        class="mdc-layout-grid__cell mdc-layout-grid__cell--span-3-desktop mdc-layout-grid__cell--span-2-tablet mdc-layout-grid__cell--span-4-phone">
        <div id="scrollable-sidebar" class="sidebarcontainer__sidebar">
          <ng-container *ngTemplateOutlet="sidebar"></ng-container>
        </div>
      </div>
      }
      <div
        [class]="
          (size$ | async) !== 'phone'
            ? 'mdc-layout-grid__cell mdc-layout-grid__cell--span-9-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-4-phone sidebarcontainer__content'
            : 'mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-4-phone sidebarcontainer__content'
        ">
        <div class="sidebarcontainer__header">
          <ng-container *ngTemplateOutlet="header"></ng-container>
        </div>
        <div class="sidebarcontainer__section">
          <ng-container *ngTemplateOutlet="section"></ng-container>
        </div>
      </div>
    </div>
  </div>
  @if ((size$ | async) === 'phone') {
  <ng-template #bottomsheetContent>
    <ng-container *ngTemplateOutlet="sidebar"></ng-container>
  </ng-template>
  <div class="sidebarcontainer__action" [class.show-mobile-bottom-bar]="showMobileBottomBar">
    <button type="button" class="sidebarcontainer__actionbutton" (click)="expandMobileBottomsheet(showMobileBottomBar)">
      <mat-icon>expand_less</mat-icon>
    </button>
  </div>
  }
</section>
